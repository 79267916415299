import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HomePageRoutingModule } from './home-routing.module';
import { AssetSelectComponent } from './pages/asset-select/asset-select.component';
import { AngularMaterialModule, SharedModule } from '@app-shared-modules';
import { AssetNamePipe, SafeHtmlPipe, ShipmentStopDatePipe, TaskStatusPipe, TrailerDisplayNamePipe, TripStopIconPipe } from '@app-pipes';
import { ChatComponent } from './pages/chat/chat.component';
import { ActionButtonsComponent } from './components/chat-action-buttons/action-buttons.component';
import { ChatSendMessageComponent } from './components/chat-send-message/chat-send-message.component';
import { ChatTruckAndTrailerInfoComponent } from './components/chat-truck-and-trailer-info/chat-truck-and-trailer-info.component';
import { ChatMessagesComponent } from './components/chat-messages/chat-messages.component';
import { TrailerManagementComponent } from './popups/trailer-management/trailer-management.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { TripsAndShipmentsComponent } from './pages/trips-and-shipments/trips-and-shipments.component';
import { ChatShowMyTripsComponent } from './components/chat-show-my-trips/chat-show-my-trips.component';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { ShipmentViewComponent } from './components/shipment-view/shipment-view.component';
import { ShipmentStopsComponent } from './components/shipment-stops/shipment-stops.component';
import { ShipmentStopTasksComponent } from './components/shipment-stop-tasks/shipment-stop-tasks.component';
import { TaskStatusPopupComponent } from './popups/task-status-popup/task-status-popup.component';
import { CreatePdfComponent } from './popups/create-pdf/create-pdf.component';
import { ChatPreviewComponent } from './components/chat-preview/chat-preview.component';
import { PaintComponent } from './components/paint/paint.component';
import { PhonebookComponent } from './pages/phonebook/phonebook.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ImagePreviewFullscreenComponent } from './popups/image-preview-fullscreen/image-preview-fullscreen.component';
import { PdfViewerComponent } from './popups/pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { EventsComponent } from './pages/events/events.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { MatRippleModule } from "@angular/material/core";
import {ShipmentViewCardComponent} from "./components/shipment-view-card/shipment-view-card.component";
import {
  ShipmentCardTimestampsComponent
} from "./components/shipment-card-timestamps/shipment-card-timestamps.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HomePageRoutingModule,
        SharedModule,
        MatDialogModule,
        AngularMaterialModule,
        NgxExtendedPdfViewerModule,
        PinchZoomModule,
        MatRippleModule
    ],
    declarations: [
        IndexPageComponent,
        AssetSelectComponent,
        ActionButtonsComponent,
        ChatComponent,
        PdfViewerComponent,
        AssetNamePipe,
        TrailerDisplayNamePipe,
        TripStopIconPipe,
        ChatSendMessageComponent,
        ChatTruckAndTrailerInfoComponent,
        ChatMessagesComponent,
        TrailerManagementComponent,
        ImagePreviewComponent,
        TripsAndShipmentsComponent,
        ChatShowMyTripsComponent,
        TaskStatusPipe,
        ShipmentViewComponent,
        VideoCallComponent,
        ShipmentStopsComponent,
        ShipmentStopDatePipe,
        ShipmentStopTasksComponent,
        TaskStatusPopupComponent,
        EventsComponent,
        CreatePdfComponent,
        ChatPreviewComponent,
        SafeHtmlPipe,
        PaintComponent,
        PhonebookComponent,
        ContactDetailsComponent,
        ImagePreviewFullscreenComponent,
        ShipmentViewCardComponent,
        ShipmentCardTimestampsComponent
    ],
    exports: [
        AssetNamePipe
    ],
    providers: [
        AssetNamePipe,

    ]
})
export class HomeModule { }
