import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-preview-fullscreen',
  templateUrl: './image-preview-fullscreen.component.html',
  styleUrls: ['./image-preview-fullscreen.component.scss'],
})
export class ImagePreviewFullscreenComponent {

  public img;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.img = data;
  }

}
