import { ChangeDetectorRef, Component, OnInit, SecurityContext } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { BehaviorSubject } from 'rxjs';
import { FormBuilderService, CameraService, ToasterService, PlatformService } from '@app-services';
import { ImagePreviewFullscreenComponent } from '../../popups/image-preview-fullscreen/image-preview-fullscreen.component';
import { TranslateService } from '@ngx-translate/core';
import { ToasterTypes } from '@app-enums';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { CreateEventField, EventType, EventTypeFormField } from '@app-models';
import { Utils } from '@libs/shared/utils';

type FormImages = {
  [key: string]: HTMLImageElement;
};

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  public eventTypesList$ = new BehaviorSubject<EventType[]>(null);
  public displayedEventsList$ = new BehaviorSubject<EventType[]>(null);
  public selectedEventType$ = new BehaviorSubject<EventType>(null);
  public fields: EventTypeFormField[];
  public eventForm$ = new BehaviorSubject<FormGroup | null>(null);
  public images: FormImages = {};
  public readonly supportedTypes = ['text', 'textarea', 'image', 'dropdown'];
  public readonly appUrl = 'https://play.google.com/store/apps/details?id=com.d3eif.d4d ';

  constructor(
    private formBuilderService: FormBuilderService,
    private cameraService: CameraService,
    private androidPermission: AndroidPermissions,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private imageCompression: NgxImageCompressService,
    private platformService: PlatformService,
  ) {}

  ngOnInit() {
    this.fields = this.formBuilderService.defaultFormFields();

    this.formBuilderService.getAllDripEventTypes().subscribe(this.handleEventTypes);
  }

  /**
   * Handle all event types
   *
   * @param eventTypes eventType[]
   */
  private handleEventTypes = (eventTypes: EventType[]): void => {
    this.eventTypesList$.next(eventTypes);
    this.displayedEventsList$.next(eventTypes);
  };

  /**
   * Create dynamic form from loaded fields.
   *
   * @returns void
   */
  private createDynamicForm(): void {
    const eventForm = this.formBuilderService.createEventDynamicForm(this.fields);
    this.eventForm$.next(eventForm);
  }

  /**
   * Select the form type and init the form
   *
   * @param selectedEventType eventType
   */
  public onIconClick(selectedEventType: EventType): void {
    this.formBuilderService.getEventTypeById(selectedEventType.id).subscribe((value) => {
      this.fields = value.fields.map((field) => field);
      this.formBuilderService.defaultFormFields().map((field) => this.fields.unshift(Utils.clone(field)));

      this.createDynamicForm();
      this.selectedEventType$.next(selectedEventType);
    });
  }

  /**
   * Cancel the form builder, go step back
   */
  public onPreviousPage(): void {
    this.fields = this.formBuilderService.defaultFormFields(); // set default form
    this.selectedEventType$.next(null);
  }

  public onAddNewEvent(): void {
    const currentForm = this.eventForm$.value;

    if (!currentForm || currentForm.invalid) {
      this.toasterService.showMessage(this.translateService.instant('eventHandler.requiredMissing'), ToasterTypes.ERROR);
      return;
    }

    let finalFields: CreateEventField[] = [];

    this.fields.forEach((field) => {
      if (field.id) {
        finalFields.push({
          eventFormFieldId: field.id,
          content: this.eventForm$.value.get(field.name).value,
        });
      }
    });
    this.formBuilderService
      .addNewEvent({
        eventTypeId: this.selectedEventType$.getValue().id,
        eventFormContents: finalFields,
        client: this.eventForm$.value.value.client,
        reference: this.eventForm$.value.value.reference,
        visibleForCustomer: this.eventForm$.value.value.visibleForCustomer,
      })
      .subscribe(() => {
        this.onPreviousPage();
        this.eventForm$.value.reset();
        this.images = {};
        this.toasterService.showMessage(this.translateService.instant('eventHandler.successCreate'), ToasterTypes.SUCCESS);
      });
  }

  /**
   * Cancel the form builder, go step back
   */
  public onCancel(): void {
    this.onPreviousPage();
  }

  public onOpenApp(): void {
    if (this.platformService.isMobileApp()) {
      window.open(this.appUrl, '_system', 'location=yes');
    } else {
      window.location.reload();
    }
  }

  /**
   * Take a Photo and add to form
   *
   * @returns void
   */
  public onAddImage(forInput: string): void {
    if (this.platformService.isMobileApp()) {
      this.androidPermission.requestPermission(this.androidPermission.PERMISSION.CAMERA).then((permissionRequest) => {
        if (permissionRequest.hasPermission) {
          this.takeAPhoto(forInput);
        }
      });
    } else {
      this.takeAPhoto(forInput);
    }
  }

  /**
   * Open camera(mobile) or upload(web) image
   *
   * @returns void
   */
  private takeAPhoto(forInput: string): void {
    this.cameraService.takePhoto().then(async (value) => {
      const compressedImage = await this.imageCompression.compressFile(value.dataUrl, DOC_ORIENTATION.Default, 80, 80, 1500, 1500);

      const tempImage = new Image();
      tempImage.onload = () => {
        this.images[forInput] = tempImage;
        this.cdr.detectChanges();
        this.eventForm$.value.get(forInput).patchValue(tempImage.src);
      };
      tempImage.src = this.domSanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this.domSanitizer.bypassSecurityTrustResourceUrl(compressedImage),
      );
    });
  }

  /**
   * Remove image from the array
   *
   * @param inputLabel The input field the image belongs to
   */
  public onRemoveImage(inputLabel: string): void {
    delete this.images[inputLabel];
    this.eventForm$.value.get(inputLabel).patchValue(null);
  }

  /**
   * Fullscreen the image
   *
   * @param image Image
   */
  public onPreviewImage(image: HTMLImageElement): void {
    this.dialog.open(ImagePreviewFullscreenComponent, {
      data: image.src,
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      panelClass: 'full-preview',
    });
  }
}
