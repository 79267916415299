// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-container {
  padding: 0px !important;
}

.img-wrapper {
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.content {
  height: 93%;
  max-height: 100%;
  padding: 0px;
  margin: 0px;
}

.actions {
  height: 7%;
  padding: 10px;
}

.full {
  max-width: 100%;
  max-height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImltYWdlLXByZXZpZXctZnVsbHNjcmVlbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHVCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLFlBQUE7RUFDQSxhQUFBO0VBQ0EsdUJBQUE7RUFDQSxtQkFBQTtFQUNBLGFBQUE7QUFDRjs7QUFFQTtFQUNFLFdBQUE7RUFDQSxnQkFBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBRUE7RUFDRSxVQUFBO0VBQ0EsYUFBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtFQUNBLGdCQUFBO0FBQ0YiLCJmaWxlIjoiaW1hZ2UtcHJldmlldy1mdWxsc2NyZWVuLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1hdC1kaWFsb2ctY29udGFpbmVyIHtcbiAgcGFkZGluZzogMHB4ICFpbXBvcnRhbnQ7XG59XG5cbi5pbWctd3JhcHBlciB7XG4gIG92ZXJmbG93OiBoaWRkZW47XG4gIGhlaWdodDogMTAwJTtcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIHBhZGRpbmc6IDEwcHg7XG59XG5cbi5jb250ZW50IHtcbiAgaGVpZ2h0OiA5MyU7XG4gIG1heC1oZWlnaHQ6IDEwMCU7XG4gIHBhZGRpbmc6IDBweDtcbiAgbWFyZ2luOiAwcHg7XG59XG5cbi5hY3Rpb25zIHtcbiAgaGVpZ2h0OiA3JTtcbiAgcGFkZGluZzogMTBweDtcbn1cblxuLmZ1bGwge1xuICBtYXgtd2lkdGg6IDEwMCU7XG4gIG1heC1oZWlnaHQ6IDEwMCU7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/web/src/app/modules/home/popups/image-preview-fullscreen/image-preview-fullscreen.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;AACA,ojCAAojC","sourcesContent":[".mat-dialog-container {\n  padding: 0px !important;\n}\n\n.img-wrapper {\n  overflow: hidden;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n}\n\n.content {\n  height: 93%;\n  max-height: 100%;\n  padding: 0px;\n  margin: 0px;\n}\n\n.actions {\n  height: 7%;\n  padding: 10px;\n}\n\n.full {\n  max-width: 100%;\n  max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
