export enum StorageKeys {
  TOKEN = 'token',
  LOGGED_IN = 'logged_in',
  LANGUAGE = 'lang',
  TRUCK_ID = 'truck_id',
  TRUCK_DISPLAY_NAME = 'truck_display_name',
  TRAILER_ID = 'trailer_id',
  SHIPMENT_ID = 'shipment_id',
  SHIPMENT_STOP_ID = 'shipment_stop_id',
  TASK_ID='task_id',
  SHIPMENT_STOP_NAME = 'shipment_stop_name',
  CHAT_PHOTO_MODE = 'chat_photo_mode',
  LAST_USER_ID = 'last_user_id',
  LAST_USED_TRUCK_ID = 'last_used_truck_id',
  USER_ID = 'user_id',
  USER = 'user',
  REFRESH_TOKEN = 'refresh_token'
}
