import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Paths } from '@app-enums';
import { AssetSelectedGuard, AuthGuard, ShipmentSelectedGuard } from '@app-guards';
import { AssetSelectComponent } from './pages/asset-select/asset-select.component';
import { ChatComponent } from './pages/chat/chat.component';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { TripsAndShipmentsComponent } from './pages/trips-and-shipments/trips-and-shipments.component';
import { ShipmentStopsComponent } from './components/shipment-stops/shipment-stops.component';
import { ShipmentStopTasksComponent } from './components/shipment-stop-tasks/shipment-stop-tasks.component';
import { ChatPreviewComponent } from './components/chat-preview/chat-preview.component';
import { PaintComponent } from './components/paint/paint.component';
import { PhonebookComponent } from './pages/phonebook/phonebook.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { CreatePdfComponent } from './popups/create-pdf/create-pdf.component';
import { EventsComponent } from './pages/events/events.component';

const routes: Routes = [
  {
    path: Paths.EMPTY,
    component: IndexPageComponent,
    children: [
      {
        path: Paths.EMPTY,
        pathMatch: 'full',
        redirectTo: Paths.CHAT
      },
      {
        path: Paths.SELECT_ASSET,
        component: AssetSelectComponent,
        canActivate: [AuthGuard]
      },
      {
        path: Paths.CHAT,
        component: ChatComponent,
        canActivate: [AuthGuard, AssetSelectedGuard]
      },
      {
        path: Paths.HOME_PHONEBOOK,
        children: [
          {
            path: Paths.EMPTY,
            component: PhonebookComponent
          },
          {
            path: ':contactId',
            component: ContactDetailsComponent
          }
        ],
        canActivate: [AssetSelectedGuard]
      },
      {
        path: Paths.TRIPS_AND_SHIPMENTS,
        component: TripsAndShipmentsComponent,
        canActivate: [AssetSelectedGuard]
      },
      {
        path: Paths.SHIPMENT_STOPS,
        component: ShipmentStopsComponent,
        canActivate: [ShipmentSelectedGuard]
      },
      {
        path: Paths.SHIPMENT_STOPS_TASKS,
        component: ShipmentStopTasksComponent,
        canActivate: [ShipmentSelectedGuard]
      },
      {
        path: Paths.CHAT_PREVIEW,
        component: ChatPreviewComponent
      },
      {
        path: Paths.CREATE_PDF,
        component: CreatePdfComponent
      },
      {
        path: Paths.PAINT,
        component: PaintComponent
      },
      {
        path: Paths.HOME_EVENT,
        component: EventsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule { }
